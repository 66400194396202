import { render, h } from "vue";
import type { RendererNode } from "vue";
import WarningIcon from "~/assets/svg/notifications/warning.svg";
import ErrorIcon from "~/assets/svg/notifications/error.svg";
import Notification from "~/components/VNotification/VNotification.vue";
import type { INotification } from "~/components/VNotification/VNotification.types";

interface IUseNotificationProps extends INotification {
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
}

const parentElement =
  typeof document !== "undefined" && document.createElement("div");

const destroy = (element: HTMLDivElement) => {
  render(null, element);
};

const mount = (component: RendererNode, props: IUseNotificationProps) => {
  if (!parentElement) {
    return;
  }
  const vNode = h(component, props);
  destroy(parentElement);
  render(vNode, parentElement);
};

const useNotification = () => {
  return {
    custom(props: IUseNotificationProps) {
      mount(Notification, props);
    },
    warning(props: Omit<IUseNotificationProps, "icon">) {
      mount(Notification, {
        icon: WarningIcon,
        ...props,
      });
    },
    error(props?: Omit<IUseNotificationProps, "icon">) {
      mount(Notification, {
        title: "Ошибка",
        text: "Что-то пошло не так. Попробуйте чуть позже",
        icon: ErrorIcon,
        ...props,
      });
    },
  };
};

export default useNotification;
